/**
 * @file Privacy Policy Page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import logo from '../images/logo.png'
import Layout from '../components/templates/Layout'

const FullScreenContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  padding: 200px 20px 100px 20px;
  max-width: 450px;

  h3 {
    padding-bottom: 2px;
  }

  p {
    font-size: 1.1em;
    padding-bottom: 20px;
  }
`

const Logo = styled.img`
  height: 50px;
  object-fit: contain;
  margin-bottom: 20px;
`

const Privacy = () => {
  return (
    <Layout hideHeader>
      <FullScreenContainer>
        <Container>
          <a href="/">
            <Logo src={logo} />
          </a>
          <h2 style={{ paddingBottom: 20 }}>Privacy Policy</h2>
          <p>
            <strong>Last Updated:</strong> September 14, 2020
          </p>
          <p>
            This Privacy Policy describes how Solo Club, Inc. and its
            subsidiaries (collectively, “Solo Club,” “we,” “our” or “us”)
            collect, use, process and share personal information. This Policy
            applies to visitors and users (individually, “you”) of Solo Club’s
            websites, applications, social media accounts, and other services
            (collectively, our “Services”). You can identify the Solo Club
            company that hosts a particular Service and is responsible for your
            information under this Policy here. Please note that your use of our
            Services is additionally subject to the Solo Club Terms of Use.
          </p>
          <p>
            Solo Club helps connect diners and restaurants. This involves
            sharing personal information with third parties, including
            restaurants, restaurant groups, and restaurant affiliates, amongst
            others. In some cases, these third parties may collect personal
            information directly from you and share it with us.
          </p>
          <p>
            For more information about the information we share with third
            parties and the recipients of such information, please refer to the
            How We Share Your Information section of this Policy. Please note
            that this Policy does not otherwise cover the use of personal
            information by third parties or their services, and we do not take
            responsibility for their privacy practices.
          </p>
          <p>
            You may have certain rights or choices that relate to your personal
            information, including choices about how we share your personal
            information with others. For more information, please refer to the
            Your Choices and Rights section of this Policy.
          </p>
          <h3>Information We Collect and Use</h3>
          <p>
            When you use or visit our Services, we collect information directly
            from you (e.g., when you search for or make an online reservation).
            We may also generate information about you (e.g., information about
            your device while you use our mobile application). In some cases, we
            also obtain personal information from third parties (e.g.,
            restaurants, business partners, our group companies, or other third
            parties).
          </p>
          <h3>How We Use Your Information</h3>
          <p>
            We use your information to provide our Services, tailor your
            experience, show you bookings and transactions made through Solo
            Club and our group companies, send you marketing communications,
            provide you with more relevant advertising and offers that may be of
            interest to you, and for other purposes described below.
          </p>
          <h3>How We Share Your Information</h3>
          <p>
            We share your information with restaurants and restaurant
            affiliates; with our group companies); with our service providers
            (including payment processors); with other business partners
            (including advertising partners); with social networking services;
            with third-party services or applications you use to log into your
            Solo Club account; and others as described below. We share
            information to provide and improve our Services, for our own
            marketing purposes, to facilitate offers and advertising from others
            that may interest you, and for other purposes as described below.
          </p>
          <h3>How We Store and Protect Your Information</h3>
          <p>
            We maintain technical, administrative, and physical security
            measures designed to protect your information. We may retain your
            information for as long as your account is active and for a period
            of time thereafter to allow you to re-activate your account without
            loss of information, and as necessary for purposes described below.
          </p>
          <h3>Cookies</h3>
          <p>
            We use cookies and similar technologies to help provide our
            Services, offer you a more personalized user experience and market
            our and third parties' products and services to you.
          </p>
          <h3>Your Choices and Rights</h3>
          <p>
            You have choices about your information, and in some circumstances,
            you may be able to opt-out of receiving marketing communications,
            change your communication preferences, access and update your
            information, update location sharing options, exercise choices
            regarding cookies, and/or opt-out of certain sharing of information
            with third parties (including our group companies, restaurant groups
            and restaurant affiliates, and other business partners). These
            choices and other rights you may have are described in more detail
            below.
          </p>
          <h3>International Transfers of Information</h3>
          <p>
            We operate internationally, and information about you may be
            transferred to, or accessed by, entities located in countries
            outside of your home country. To protect your information, any such
            international transfers will be made in accordance with applicable
            law.
          </p>
          <h3>Links to Other Websites</h3>
          <p>
            Our Services may contain links to other websites or services that
            are not owned or controlled by Solo Club, including links to
            websites of restaurants and restaurant affiliates, our group
            companies, and other business partners (including advertisers).
          </p>
          <h3>Children</h3>
          <p>
            Our Services are not directed at or intended for use by children,
            nor do we knowingly collect information from children under 16 years
            of age.
          </p>
          <h3>Changes to This Policy</h3>
          <p>
            We update this Policy from time to time, and changes will be
            effective when posted (as identified in the Policy).
          </p>
          <h3>How to Contact Us</h3>
          <p>
            If you have any questions about this Policy or the way your personal
            information has been used, please contact us at
            firstsupperclub@gmail.com or using the contact details set out
            below.
          </p>
        </Container>
      </FullScreenContainer>
    </Layout>
  )
}

Privacy.propTypes = {}

export default Privacy
